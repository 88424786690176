/*External dependencies */
import CognitoClient from '@mancho.devs/cognito';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
/*Local dependencies */
import {
  codeRegistrationForm,
  LoginAction,
  LoginActionTypes,
  SignupConfirmCode,
  signupFailed,
  SignupPasswordRequest,
  signupSucceeded,
  ForgotPassword,
  forgotPasswordFailed,
  SetNewPassword,
  sendCodeForNewPasswordModal,
  signOutSucceeded,
  SignOutRequest,
  signupConfirmCodeFailed,
  LoginRequest,
  loginSucceeded,
  loginFailed,
} from './action';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_PASSWORD_REQUEST),
    switchMap(({ password, username }: SignupPasswordRequest) =>
      cognitoClient.signUp(username, password).then(codeRegistrationForm).catch(signupFailed),
    ),
  );
}

export function signUpConfirmCodeEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_CONFIRM_CODE),
    switchMap(({ username, confirmCode }: SignupConfirmCode) =>
      cognitoClient.signUpConfirmCode(username, confirmCode).then(signupSucceeded).catch(signupConfirmCodeFailed),
    ),
  );
}

export function signInEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.LOGIN_REQUEST),
    switchMap(({ username, password }: LoginRequest) => {
      return cognitoClient.signIn(username, password).then(loginSucceeded).catch(loginFailed);
    }),
  );
}

export function forgotPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.FORGOT_PASSWORD_REQUEST),
    switchMap(({ username }: ForgotPassword) =>
      cognitoClient.forgotPassword(username).then(sendCodeForNewPasswordModal).catch(forgotPasswordFailed),
    ),
  );
}

export function setNewPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.SET_NEW_PASSWORD_REQUEST),
    switchMap(({ username, confirmCode, newPassword }: SetNewPassword) =>
      cognitoClient
        .confirmPassword(username, confirmCode, newPassword)
        .then(loginSucceeded)
        .catch(forgotPasswordFailed),
    ),
  );
}

export function signOutEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    ofType(LoginActionTypes.SIGN_OUT_REQUEST),
    switchMap(({}: SignOutRequest) => cognitoClient.signOut().then(signOutSucceeded)),
  );
}
